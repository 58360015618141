<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                NASI KLIENCI
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                ENERGETYKA WAGON
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <!-- <v-card-title>
                    SAFE INVESTMENTS FOR THE TIMES
                  </v-card-title> -->
                  <v-card-text>
                    Nie licząc działalności związanej z obrotem i dystrybucją energii elektrycznej omówionej na stronie <a href="https://www.energetyka-wagon.pl/sprzedaz-energii/">Sprzedaż energii</a>, prowadzimy działalność w zakresie usług elektroenergetycznych oraz usług remontowych urządzeń dozorowych. Szczególowo zakresy oferowanych robót przedstawia strona <a href="https://www.energetyka-wagon.pl/uslugi/">Usługi</a>.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5 background-light-grey">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                ... do dzisiaj naszymi klientami są:
              </h2>
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="6"
                sm="12"
              >
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    <ul>
                      <li>Europejskie Konsorcjum Kolejowe WAGON Sp. z o.o. - Ostrów Wlkp</li>
                      <li>TRASKO - STAL Sp. z o.o. - Ostrów Wlkp</li>
                      <li>Intermarche (ul.Waryńskiego) - KIL Sp. z o.o. - Ostrów Wlkp</li>
                      <li>Intermarche (ul.Bema) - Rafaela Sp. z o.o. - Ostrów Wlkp</li>
                      <li>Kuźnia Ostrów Wielkopolki Sp. z o.o.</li>
                      <li>Zakład Produkcji Doświadczalnej Automatyki Sp. z o.o. - Ostrów Wlkp</li>
                      <li>Air Products Sp. z o.o. - Ostrów Wlkp</li>
                      <li>IVEST BIZNES S.A.</li>
                      <li>RADPOL S.A.</li>
                      <li>Mainpartner</li>
                      <li>Litex Promo Sp. z o.o. - Ostrów Wlkp.</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-spacer />
              <v-col
                md="6"
                sm="12"
              >
                <v-card
                  outlined
                  theme--dark
                  class="mb-5 centered text-justify"
                >
                  <v-img src="/static/e2.jpg" />
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
    data: () => ({
      highlights: [
        'Projekty i architektura',
        'Wykończenia wnętrz',
        'Inwestor zastępczy',
        'Wykonawstwo i nadzory',
        'Marketing',
        'Sprzedaż'
      ],
      experiences: [
        ['mdi-vuejs', '75', 'Vue'],
        ['mdi-vuetify', '100', 'Vuetify'],
        ['mdi-aws', '67', 'AWS'],
        ['mdi-server-security', '95', 'Security']
      ]
    })
  }
</script>
